.swiper-container {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin: auto;
  max-width: 932px;
}

.slick-prev svg{
  position: absolute;
  left: -1%;
  font-size: 30px;
  color: var(--secondary-color);
  cursor: pointer;
}


.slick-next svg{
  position: absolute;
  right: -1%;
  font-size: 30px;
  color: var(--secondary-color);
  cursor: pointer;
}

.slick-initialized .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
