.question-reply-container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #dee1e6ff;
  border-radius: 8px;
  background-color: var(--background-color);
}

.question-user-reply {
  display: flex;
  gap: 10px;
}

.user-data-reply p {
  margin: 0;
}

.name-user-reply {
  font-size: 1rem;
  font-weight: 700;
  color: var(--text-color);
}

.about-user-reply {
  font-size: 0.9rem;
  color: var(--text-color);
}

.title-reply {
  margin: 14px 0 5px 0;
  font-size: 1rem;
  font-weight: 700;
  color: var(--text-color);
  cursor: pointer;
}

.title-reply:hover {
  text-decoration: underline;
}

.description-reply {
  position: relative;
  margin: 0 0 14px 0;
}

.description-reply-txt {
  margin: 0;
  font-size: 1rem;
  color: var(--text-color);
  height: 86px;
  overflow: hidden;
}

.description-reply-txt-open {
  height: fit-content;
}

.more-reply {
  position: absolute;
  bottom: -24px;
  right: 0;
  font-size: 1rem;
  cursor: pointer;
  color: var(--secondary-color);
}

.valoration-question-reply {
  display: flex;
}

.positive-reply-btn {
  display: flex;
  align-items: center;
  border: 1px solid #8284884b;
  border-radius: 18px 0 0 18px;
  padding: 5px 10px;
  background-color: #dee1e67e;
  cursor: pointer;
  gap: 5px;
}

.positive-reply-btn p {
  margin: 0;
  font-weight: 500;
  color: var(--text-color);
}

.positive-reply-btn:hover {
  background-color: #8284884b;

  .positive-reply-icon {
    color: var(--secondary-color);
  }
}

.negative-reply-btn:hover {
  background-color: #8284884b;

  .negative-reply-icon {
    color: var(--secondary-color);
  }
}

.positive-reply-icon,
.negative-reply-icon {
  color: var(--text-color);
}

.negative-reply-btn {
  display: flex;
  align-items: center;
  border: 1px solid #8284884b;
  border-radius: 0 18px 18px 0;
  padding: 5px 10px;
  background-color: #dee1e67e;
  cursor: pointer;
  gap: 5px;
}
