.consultants {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
}

.consultants-body {
  padding: 32px;
}

.title-carousel {
  display: block;
  align-items: center;
  max-width: 900px;
  margin: 32px auto;
  color: var(--text-color);
}

.title-carousel h1 {
  margin-bottom: 16px;
}

.title-carousel p {
  color: #9095a0ff;
  margin: 0;
}

.subtitle {
  margin-top: 32px;
  font-weight: 500;
  color: var(--secondary-color);
}

.consultants-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.modules-container {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

h3.modules-subtitle {
  font-weight: 100;
  margin-top: 32px;
  font-size: 0.9rem;
  margin-bottom: 6px;
}



