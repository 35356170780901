.avatar-container {
  position: relative;
}

.avatar-img {
  width: 100%;
  z-index: 2;
  position: relative;
}

.avatar-color {
  position: absolute;
  bottom: -8%;
  left: 2.5%;
  width: 95%;
  height: 95%;
  border-radius: 50%;
  z-index: 1;
}
