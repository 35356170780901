.choose-avatar {
  height: 100vh;
  width: 100%;
}

.choose-avatar-container {
  display: flex;
  max-width: 900px;
  margin: auto;
}

.edit-avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  padding: 2% 4%;
}

.title-edit-avatar {
  margin: 0 0 20px 0;
  font-size: 2rem;
  font-weight: 700;
  color: var(--text-color);
}

.text-edit-avatar {
  margin: 0 0 50px 0;
  font-size: 0.9rem;
  color: var(--text-color);
}

.avatar-edit {
  position: relative;
  margin: 0 0 40px 0;
  width: 99px;
  height: 105px;
}

.circle-color {
  position: absolute;
  bottom: -22px;
  left: 60%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1;
}

.edit-color {
  display: flex;
  gap: 10px;
}

.colors-edit {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
}

.color1 {
  background-color: #00bdd6;
}

.color2 {
  background-color: #8353e2;
}

.color3 {
  background-color: #4069e5;
}

.color4 {
  background-color: #ed7d2d;
}

.color5 {
  background-color: #e05858;
}

.color6 {
  background-color: #1d2128;
}

.color7 {
  background-color: #dee1e6;
}

.avatars {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 60%;
  gap: 36px;
  padding: 5% 8%;
}

.avatar {
  width: 80px;
  cursor: pointer;
}

.avatar-selected {
  width: 120px;
  cursor: default;
}

button.avatars-save-btn {
  margin-top: 60px;
}

@media (max-width: 768px) {
  .choose-avatar-container {
    display: flex;
    flex-wrap: wrap;
  }

  .edit-avatar {
    width: auto;
  }

  .avatars {
    width: auto;
  }
}
