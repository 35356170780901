.question-container {
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  gap: 16px;
  width: 900px;
  margin: 20px auto;
}

.question img {
  height: 36px;
  width: 36px;
  border-radius: 18px;
  background: var(--background-color);
}

.question-data {
  display: flex;
  align-items: flex-start;
}

.name-user {
  margin: 0 0 0 10px;
  color: var(--text-color);
  font-size: 18px;
  font-weight: 700;
  background-color: rgb(188, 239, 255);
  padding: 8px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
}

.time-question {
  margin: 2px 0 0 10px;
  font-size: 0.8rem;
  color: #9095a0ff;
}

.question-title {
  margin: 12px 0 16px 10px;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 30px;
  color: var(--text-color);
}

.question-description {
  margin: 3px 0 0 10px;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 22px;
  color: var(--text-color);
}

.question-answer-box {
  display: flex;
  flex-direction: column;
  width: 400px;
  gap: 12px;
}

.question-answer-text {
  padding: 16px;
}

@media (max-width: 768px) {
  .question-container {
    width: auto;
    padding: 16px;
  }

  .question-answer-box {
    min-width: 280px;
    width: auto;
  }

  .description-reply-txt {
    margin-bottom: 38px;
  }

  .title-reply{
    text-decoration: underline;
  }
}
