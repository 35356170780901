:root {
  --primary-color: #ff9d00;
  --primary-hover-color: #e97c00dc;
  --secondary-color: #006eba;
  --secondary-hover-color: #09558c;
  --background-color: #f6f6f6;
  --text-color: #333;
}

.primary-color {
  color: var(--primary-color);
}

.primary-background {
  color: var(--primary-color);
  color: #000;
}

.secondary-color {
  color: var(--secondary-color);
}

.secondary-background {
  color: var(--secondary-color);
  color: #fff;
}
