.modal-chat-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  width: 800px;
  height: 600px;
  border-radius: 8px;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  background-color: white;
}

.modal-company-consultant {
  display: flex;
  height: 600px;
}

.login-companies {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 10% 30% 10% 42% 8%;
  width: 50%;
  padding: 20px;
  outline: none;
}

.title-modal {
  display: flex;
  grid-column: 1;
  grid-row: 1;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--text-color);
}

.image-modal-container {
  display: flex;
  grid-column: 1;
  grid-row: 2;
  justify-content: center;
  align-items: center;
}

.modal-type-img {
  width: 100px;
}

.question-modal-txt {
  display: flex;
  grid-column: 1;
  grid-row: 3;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 900;
  color: var(--text-color);
}

.description-modal-txt {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 0.9rem;
  line-height: 22px;
  color: #9095a0;
}

.description-modal-txt p {
  width: 80%;
  text-align: center;
}

.login-btn-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.login-consultants {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 10% 30% 10% 42% 8%;
  width: 50%;
  padding: 20px;
  border-radius: 0 8px 8px 0;
  background-color: var(--background-color);
}

@media (max-width: 768px) {
  div.modal-chat-container {
    height: fit-content;
  }

  .modal-company-consultant {
    flex-direction: column;
    height: fit-content;
    overflow: auto;
  }
  .login-companies {
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .login-consultants {
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .image-modal-container img {
    width: 60px;
  }

  .description-modal-txt {
    margin: 0;
    display: none;
  }
}
