.company-profile {
  height: 100vh;
  width: 100%;
}

.company-profile-container {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: 2fr;
  height: calc(100vh - 56px);
}

.profile {
  grid-column: 1;
  grid-row: 1/ span 2;
  padding: 2% 4%;
}

.titles-company-profile {
  font-size: 1rem;
  font-weight: 700;
  line-height: 26px;
  color: var(--text-color);
}

.profile-data {
  display: grid;
  grid-template-columns: 30% 65%;
  grid-template-rows: 40% 60%;
  gap: 10px;
}

.name-btn-profile {
  display: flex;
  flex-wrap: wrap;
  grid-column: 2;
  grid-row: 1;
}

.name-company p {
  margin: 0;
}

.name-profile {
  font-size: 1rem;
  font-weight: 700;
  color: var(--text-color);
}

.title-profile {
  font-size: 0.8rem;
  color: #565e6c;
}

.rating-projects {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  grid-column: 2;
  grid-row: 2;
}

.rating-projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 64px;
  padding: 5px 0;
  border-radius: 4px;
  background-color: var(--background-color);
}

.rating-projects-txt {
  font-size: 0.5rem;
  margin: 0;
  color: #565e6c;
}

.rating-projects-num {
  margin: 5px 0 2px 0;
  font-size: 0.8rem;
  color: var(--text-color);
}

.folder-project-icon {
  color: var(--text-color);
}

.about-title {
  margin: 10px 0 0 0;
  font-size: 0.9rem;
  font-weight: 700;
  color: var(--text-color);
}

.about-txt {
  margin: 0 0 20px 0;
  font-size: 0.9rem;
  line-height: 22px;
  color: #9095a0;
}

.form-company-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  gap: 20px;
}

.company-profile-title-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 0.8rem;
  font-weight: 700;
  gap: 5px;
}

.company-profile-form-field {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.company-profile-inputs-form {
  height: 30px;
  padding-left: 10px;
  border: 0;
  border-radius: 4px;
  outline: none;
  appearance: none;
  font-size: 1rem;
  color: var(--text-color);
  background-color: var(--background-color);
}
.company-profile-inputs-form::-webkit-outer-spin-button,
.company-profile-inputs-form::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Safari y Chrome */
  margin: 0; /* Elimina el espacio generado por las flechas */
}
.company-profile-inputs-form::placeholder {
  color: #888;
  font-weight: 200;
  font-style: italic;
}

.company-profile-selects-form {
  height: 36px;
  padding-left: 5px;
  border: 0;
  border-radius: 4px;
  outline: none;
  font-size: 1rem;
  color: var(--text-color);
  background-color: var(--background-color);
}

.company-profile-required-message {
  font-size: 0.8rem;
  color: red;
}

.modules-company {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-column: 2;
  grid-row: 1;
  height: 501px;
  padding: 2% 4%;
  align-items: flex-start;
}

.modules-company-txt {
  margin: 0 0 20px 0;
  font-size: 1.1rem;
  color: var(--text-color);
}

.sap-areas-company{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-column: 2;
  grid-row: 2;
  height: 558px;
  padding: 2% 4%;
}

.sap-areas{
  width: 100%;
  height: 500px;
}

.about-profile {
  width: 100%;
}

.name-company {
  padding-right: 12px;
}

@media (max-width: 768px) {
  .profile-data {
    display: flex;
    flex-wrap: wrap;
  }

  .company-profile-container {
    display: flex;
    flex-direction: column;
  }

  .profile {
    width: auto;
  }

  .modules-company {
    width: auto;
    height: auto;
  }

  .modules {
    height: auto;
  }
}
