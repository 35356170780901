.card-container {
  width: 275px;
  border-radius: 8px !important;
  border: 1px solid #dee1e6ff;
  padding: 10px !important;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  position: relative;
}

.card-container.focused {
  border: 1px solid var(--primary-hover-color);
}

.card-container.focused .review-btn {
  background: #efb03482;
  color: #5d4108ff;

}

.img-review {
  border-radius: 8px;
  margin-top: 5px;
  height: 150px;
}

.review-btn {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 5px 0 0 5px;
  border-radius: 14px;
  border: none;
  background: #565e6c7d;
  color: white;
  font-size: 0.8rem;
  cursor: pointer;
  position: absolute;
  left: 12px;
  top: 12px;
}

.icon-star {
  font-size: 0.8rem;
  margin: 0 0 2px 2px;
}

.img-consultant {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.certified-container {
  font-size: 12px;
  padding: 0 10px 0 0;
  margin: 0;
  align-self: flex-end; /* Alinea el texto en la parte derecha del div */
  color: #bcc1ca;
  height: fit-content;
}

.certified-title {
  margin: 0;
  padding: 0;
}

.card-content {
  padding: 0 5px !important;
}

.experience-txt {
  font-size: 0.8rem;
  color: #9095a0;
}

.reviews-section {
  display: flex;
  justify-content: space-between;
}

.reviews-section h6 {
  color: #323842;
  margin: 8px 0;
}

.see-all-btn {
  color: var(--secondary-color) !important;
  font-size: 0.8rem !important;
  text-transform: none !important;
}

.arrow-see-all {
  font-size: 0.8rem !important;
}

.reviews-container {
  background-color: var(--background-color);
  border-radius: 4px;
  padding: 0 8px;
  height: 88px;
}

.user-review-section {
  display: flex;
  height: 36px;
  justify-content: space-between;
}

.user-review {
  height: 36px;
  display: flex;
  gap: 5px;
}

.user-review p {
  margin: 0;
}

.user-review img {
  height: 36px;
  width: 36px;
  border-radius: 18px;
  background: var(--background-color);
}

.name-user-review {
  font-size: 0.8rem;
  font-weight: 700;
}

.time-review {
  font-size: 0.8rem;
  font-weight: 400;
}

.review-txt p {
  font-size: 0.8rem;
  color: var(--text-color);
  margin: 8px 0 0 0;
}

.hire-btn {
  width: 270px !important;
}


.icon-hire-btn {
  font-size: 0.9rem !important;
}
