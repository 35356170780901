.question-replies-container {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #dee1e6ff;
  border-radius: 8px;
  background-color: var(--background-color);
}

.question-user-replies {
  display: flex;
  gap: 10px;
}

.user-data-replies p {
  margin: 0;
}

.name-user-replies {
  font-size: 1rem;
  font-weight: 700;
  color: var(--text-color);
}

.about-user-replies {
  font-size: 0.9rem;
  color: var(--text-color);
}

.title-replies {
  margin: 14px 0 5px 0;
  font-size: 1rem;
  font-weight: 700;
  color: var(--text-color);
  cursor: pointer;
}

.title-replies:hover {
  text-decoration: underline;
}

.description-replies {
  position: relative;
  margin: 0 0 14px 0;
}

.description-replies-txt {
  margin: 0;
  font-size: 1rem;
  color: var(--text-color);
  max-height: 86px;
  overflow: hidden;
}

.description-replies-txt-open {
  height: fit-content;
  max-height: fit-content
}

.more-replies {
  position: absolute;
  bottom: -24px;
  right: 0;
  font-size: 1rem;
  cursor: pointer;
  color: var(--secondary-color);
}

.valoration-question-replies {
  display: flex;
}

.positive-replies-btn {
  display: flex;
  align-items: center;
  border: 1px solid #8284884b;
  border-radius: 18px 0 0 18px;
  padding: 5px 10px;
  background-color: #dee1e67e;
  cursor: pointer;
  gap: 5px;
}

.positive-replies-btn p {
  margin: 0;
  font-weight: 500;
  color: var(--text-color);
}

.positive-replies-btn:hover {
  background-color: #8284884b;

  .positive-replies-icon {
    color: var(--secondary-color);
  }
}

.negative-replies-btn:hover {
  background-color: #8284884b;

  .negative-replies-icon {
    color: var(--secondary-color);
  }
}

.positive-replies-icon,
.negative-replies-icon {
  color: var(--text-color);
}

.negative-replies-btn {
  display: flex;
  align-items: center;
  border: 1px solid #8284884b;
  border-radius: 0 18px 18px 0;
  padding: 5px 10px;
  background-color: #dee1e67e;
  cursor: pointer;
  gap: 5px;
}
