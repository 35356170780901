.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px;

  h1 {
    margin: 0;
    margin-bottom: 16px;
    color: var(--text-color);
    font-weight: 400;
  }

  h3.signin {
    margin: 0;
    margin-top: 16px;
    text-align: center;
  }

  h6 {
    margin: 0px 0px 20px 10px;
    color: #9095a0ff;
    font-weight: 400;
    font-size: 13px;
    text-align: center;
  }

  p {
    text-align: center;
    color: var(--text-color);
  }

  .login-container-modal {
    display: flex;
    width: fit-content;
    margin: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    background-color: white;
  }

  .login-left-side {
    display: flex;
    flex-direction: column;
    width: 300px;
    align-items: center;
    padding: 50px;
    background-color: var(--background-color);
    color: #fff;

    h3.signin {
      color: var(--primary-color);
    }
  }

  .input-form {
    background-color: #fff;
    box-shadow: 0 2px 5px #0004;
    border-radius: 4px;
    height: 38px;
    padding: 0 16px;
    border: 0;
    margin: 10px;
  }

  .text-left-side {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    color: var(--text-color);
  }

  .providers-btns {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  button.login-btn {
    width: 260px;
    margin: 10px;
    padding: 5px 20px;
    border: none;
    border-radius: 4px;
    font-size: 1.1rem;
    text-transform: none;
  }

  button.google-btn {
    color: #c71610;
    background-color: var(--background-color);

    &:hover {
      color: var(--background-color);
      background-color: #c71610;
      box-shadow: none;
    }
  }

  button.microsoft-btn {
    color: #379ae6;
    background-color: var(--background-color);

    &:hover {
      color: var(--background-color);
      background-color: #379ae6;
      box-shadow: none;
    }
  }

  .apple-btn {
    color: #565e6c;
    background-color: var(--background-color);

    &:hover {
      color: var(--background-color);
      background-color: #565e6c;
      box-shadow: none;
    }
  }

  .get-stated-btn {
    color: #ffffff;
    background-color: var(--primary-color);
    &:hover {
      color: #ffffff;
      background-color: var(--primary-color);
    }
  }

  .register-company-btn {
    color: var(--primary-color);
    background-color: #ffffff;
  }

  .login-right-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 300px;
    align-items: center;
    padding: 50px;
    border-radius: 8px;

    .signin {
      color: var(--secondary-color);
    }
  }

  .cognisap-logo-login {
    width: 300px;
  }

  a.link-back {
    color: var(--secondary-color);
    cursor: pointer;
    margin-top: 16px;
  }

  a.link-back:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 800px) {
    .login-container-modal {
      flex-direction: column;
    }
  }
}
