.question-modal-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  width: 600px;
  max-height: 550px;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  padding: 20px;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.5);
  background-color: var(--background-color);
}

.header-question-modal {
  display: flex;
  justify-content: space-between;
}

h4.title-question-modal {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--text-color);
}

.close-question-modal {
  cursor: pointer;
}

.close-modal-btn {
  font-size: 1.5rem;
  color: var(--text-color);
}

.form-new-question {
  display: flex;
  height: 100vh;
  gap: 12px;
  flex-direction: column;
  margin: 15px 0 0 0;
  grid-template-columns: 33% 23% 44%;
}

.message-question-modal {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  color: black;
  background-color: #BBDEFB;
}

.message-question-modal p {
  margin: 0 8px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.message-question-modal ul {
  margin-top: 6px;
  margin-bottom: 0;
}

.title-question {
  grid-column: 1 / span 2;
  grid-row: 3;
  margin-top: 10px;
  padding: 0 20px 0 0;
}

.subtitles-question-form {
  margin: 0 0 5px 0;
  font-weight: bold;
  color: var(--text-color);
}

.title-question-input {
  display: flex;
  height: 36px;
  align-items: center;
  border: 1px solid #bcc1caff;
  border-radius: 4px;
  padding: 0 10px;
}

.question-input {
  width: 100%;
  outline: none;
  font-size: 0.9rem;
  border: none;
  color: var(--text-color);
  background-color: var(--background-color);
}

.question-input::placeholder {
  color: #888;
  font-weight: 200;
  font-style: italic;
}

.description-question {
  grid-column: 1 / span 2;
  grid-row: 4;
  padding: 0 20px 0 0;
}

.description-question-input {
  width: 95%;
  height: 80%;
  padding: 10px;
  border: 1px solid #bcc1caff;
  border-radius: 4px;
  resize: none;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  color: var(--text-color);
  background-color: var(--background-color);
}

.description-question-input::placeholder {
  color: #888;
  font-weight: 200;
  font-style: italic;
}

.attachments-question {
  width: 91%;
  grid-column: 3;
  grid-row: 1 / span 3;
}

.attachments-question-files {
  height: 70%;
  border: 1px solid #bcc1caff;
  border-radius: 4px;
  padding: 15px 0 20px 20px;
  background-color: var(--background-color);
}

.attachments-question-files p {
  display: flex;
  flex-wrap: wrap;
  margin: 0 15px 15px 0;
  overflow: hidden;
  font-size: 0.9rem;
  color: var(--text-color);
}

.upload-question-files {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 90%;
  margin: 25px 0 0 0;
  border: 2px dashed #bcc1caff;
  background-color: var(--background-color);
  grid-column: 3;
  grid-row: 4;
}

.upload-question-icon {
  font-size: 3rem;
  color: #bcc1caff;
}

.first-upload-question-txt {
  margin: 0;
  font-size: 0.9rem;
  color: var(--text-color);
}

.second-upload-question-txt {
  margin: 4;
  font-size: 0.8rem;
  color: #bcc1caff;
}

.upload-file-question-btn {
  padding: 4px;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  font-size: 0.8rem;
  color: var(--secondary-color);
  cursor: pointer;
}

.file-question-input {
  display: none;
}

.topics-action-question-btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
  grid-column: 1 / span 3;
  grid-row: 5;
}

.detected-question-txt {
  display: flex;
  align-items: center;
  height: 28px;
  gap: 10px;
}

.detected-question-txt p {
  font-size: 0.8rem;
  color: var(--text-color);
}

.topic-question-btn {
  height: 28px !important;
  border-radius: 14px !important;
  font-size: 0.8rem !important;
  color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.action-question-btns {
  display: flex;
  gap: 10px;
}

.cancel-question-btn {
  height: 36px !important;
  border-radius: 4px !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  text-transform: none !important;
  color: #9095a0ff !important;
  background-color: #00000000 !important;
}

.cancel-question-btn:hover {
  color: #9095a0ff !important;
  background: #eaecefff !important;
}

.create-question-btn {
  height: 36px !important;
  border-radius: 4px !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  text-transform: none !important;
  color: white !important;
  background-color: var(--secondary-color) !important;
}

.create-question-btn:hover {
  color: #ffffffff !important;
  background: var(--secondary-hover-color) !important;
}

@media (max-width: 800px) {
  .question-modal-container {
    width: 95%;
    min-height: 93%;
    overflow-y: scroll;
  }

  .form-new-question {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .upload-question-files {
    padding: 20px 0;
  }
}
