body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.night-mode {
  background: #222;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* app bar */
header.MuiPaper-root {
  background: #f6f6f6;
  border-bottom: 1px solid #ccc;
}

.night-mode header.MuiPaper-root {
  background: #1e2023;
  border-bottom: 1px solid #000;
}

.MuiMenu-paper {
  min-width: 230px !important;
}

/* Estilo personalizado para la barra de desplazamiento */
::-webkit-scrollbar {
  width: 5px; /* Ancho de la barra de desplazamiento */
}

/* Estilos para el riel de la barra de desplazamiento */
::-webkit-scrollbar-track {
  background-color: #c5d1f7ff; /* Color del fondo del riel */
  border-radius: 5px; /* Borde redondeado */
}

/* Estilos para el mango (thumb) de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
  background-color: #379ae6ff;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #379;
}

button.btn-search {
  background-color: #ff9d00;
  color: #ffffff;
  border-radius: 24px;
  margin-left: 16px;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
}

button.btn-search:hover {
  background-color: #d77803;
}

div {
  outline: none !important;
}

div:focus-visible {
  outline: none !important;
}

button.btn-primary {
  background: var(--primary-color);
  text-transform: none;
  color: #000;
  font-size: 14px;
  height: 36px;
  width: 260px;
}

button.btn-secondary {
  background: var(--secondary-color);
  text-transform: none;
  color: #fff;
  font-size: 14px;
  height: 36px;
  width: 260px;
}

button.btn-primary:hover {
  background: var(--primary-hover-color);
}

button.btn-secondary:hover {
  background: var(--secondary-hover-color);
}