.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 200px;
}

.home-container p {
  color: #9095a0;
  margin: 0;
}

.cognisap-logo-home {
  width: 523px;
}

.logo-text-helper {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.search-section {
  display: flex;
  justify-content: space-between;
  width: 600px;
  margin-top: 50px;
  padding: 8px;
  border: 1px solid #bcc1caff;
  border-radius: 34px;
  border-width: 1px;
  background-color: #fff;
}

.search-bar {
  width: 100%;
  border: none;
  outline: none;
  padding-left: 16px;
  font-size: 1.1rem;
  color: #565e6c;
}

.search-bar::placeholder {
  color: #888;
  font-weight: 200;
  font-style: italic;
}

.mic-btn {
  border: none;
  background-color: transparent;
}

.end-record {
  color: red;
}

.text-helper {
  background: var(--secondary-color);
  color: #fff;
  padding: 4px 16px;
  border-radius: 16px;
  margin: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Estilo del fondo oscuro */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro con transparencia */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Asegura que esté por encima de otros elementos */
}

.banner {
  position: absolute;
  width: 100%;
  height: 80px;
  background-color: #f6f6f6;
  bottom: 0;
  border-top: 3px solid #f2f2f2;
}

.login-link {
  position: absolute;
  right: 32px;
  top: 16px;
  color: var(--secondary-color);
  cursor: pointer;
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .cognisap-logo-home {
    width: 70%;
  }

  .home-container p {
    color: #565e6c;
    margin: 0;
    font-size: small;
  }

  .search-section {
    width: 90%;
  }

  .search-bar {
    border: none;
    font-size: small;
    width: 100%;
  }
}
