.chat {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 57px);
}

.chat-gpt-container {
  display: flex;
  z-index: 1;
}

.chat-history-container {
  width: 400px;
  padding: 16px;
  height: calc(100vh - 88px);
  background-color: aliceblue;
}

.chat-main-container {
  width: calc(100% - 400px);
  height: calc(100vh - 57px);
}

.chat-container {
  width: 100%;
  height: calc(100% - 270px);
  overflow-y: auto;
}

.chat-inside-container {
  display: flex;
  padding: 0;
}

.chat-text {
  width: 100%;
  padding: 32px 20%;
  color: var(--text-color);
}

.outgoing-message {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.message {
  padding: 10px 24px;
  margin: 4px;
  border-radius: 8px;
  word-wrap: break-word;
  font-size: 0.9rem;
}

.message > p {
  margin: 0;
  margin-bottom: 12px;
}

.received-message {
  position: relative;
  max-width: 600px;
  margin-right: 32px;
  border-top-left-radius: 0;
  background-color: #f0f8ff; /* Color de fondo para mensajes recibidos */
}

.received-message:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: -14px;
  top: 0;
  border: 8px solid #f0f8ff;
  border-color: #f0f8ff #f0f8ff transparent transparent;
}

.received-message:before {
  content: "";
  position: absolute;
  width: 32px;
  height: 32px;
  left: -64px;
  top: -10px;
  border: 8px solid #f0f8ff;
  border-radius: 50%;
  background-image: url("../../assets/images/icon.png");
  background-size: 32px;
  background-color: aliceblue;
  background-repeat: no-repeat;
}

.sent-message {
  position: relative;
  max-width: 600px;
  margin-left: 32px;
  border-bottom-right-radius: 0;
  text-align: right;
  background: #ffefd4;
}

.sent-message:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  right: -14px;
  bottom: 0;
  border: 8px solid #ffefd4;
  border-color: transparent transparent #ffefd4 #ffefd4;
}

.waiting-animation {
  display: flex;
  justify-content: left;
  padding-left: 25%;
}

.chat-divider {
  margin-top: 32px;
}

.ask-expert {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin: 0 0 7px 0;
  padding-right: 21%;
  font-size: 0.9rem;
  color: var(--text-color);
}

.expert-btn {
  border-radius: 18px !important;
  width: 170px !important;
}

.input-chat-suggestions {
  padding: 0 21% 0 20%;
  margin-bottom: 100px;
}

.input-chat {
  display: flex;
  height: 48px;
  align-items: center;
  margin-bottom: 12px;
  padding: 0 10px 0 16px;
  border-radius: 24px;
  border: solid 1px #bcc1caff;
  gap: 8px;
  background-color: #fff;
}

.bar-chat {
  width: 100%;
  resize: none;
  overflow-y: scroll;
  border: none;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  color: #565e6c;
}

.bar-chat::placeholder {
  color: #888;
  font-weight: 200;
  font-style: italic;
}

.bar-chat:disabled {
  background-color: white;
}

.bar-chat::-webkit-scrollbar {
  display: none;
}

.suggestions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.9rem;
  gap: 12px;
  color: #9095a0ff;
}

button.suggestions-btn {
  height: fit-content;
  border-color: #bcc1caff;
  border-radius: 14px;
  text-transform: none;
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--secondary-color);
  background-color: #e1f5fe;
}

.LoadingPage {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.app-bar-chat {
  z-index: 2;
}

@media screen and (max-width: 600px) {
  .chat-text {
    width: 91%;
    margin: 0 12px;
    padding: 4px;
  }

  .waiting-animation {
    display: flex;
    justify-content: left;
    padding-left: 5%;
  }

  .ask-expert {
    padding-right: 5%;
  }

  .input-chat-suggestions {
    padding: 0 2%;
    margin: 6px;
  }

  .suggestions {
    gap: 4px;
    margin-bottom: 6px;
  }

  .suggestions p {
    margin: 0;
  }

  .chat-history-container {
    width: 100%;
    padding: 0;
    height: 42px;
    border-radius: 0;
  }

  .chat-gpt-container {
    flex-direction: column;
    height: 100%;
  }

  .chat-main-container {
    width: 100%;
    height: calc(100% - 12px);
  }

  code {
    white-space: pre-line;
  }
}
