.register-company-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  width: 800px;
  border-radius: 8px;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  background-color: white;
  min-height: 600px;
}

.modal-register-company {
  display: flex;
  min-height: 600px;
}

.register-company-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 20px 30px 30px 30px;
}

.title-register-company {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  font-size: 1.8rem;
  color: var(--text-color);
}

.register-company-form-txt {
  display: flex;
  text-align: center;
  margin: 0 0 20px 0;
  font-size: 14px;
  color: #888;
}

.register-company-form-txt p {
  margin: 0;
}

.register-company-form-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  gap: 20px;
}

.register-company-form-field {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title-register-company-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 0.8rem;
  font-weight: 700;
  gap: 5px;
}

.inputs-register-company-form {
  height: 30px;
  padding-left: 10px;
  border: 0;
  border-radius: 4px;
  outline: none;
  appearance: none;
  font-size: 1rem;
  color: var(--text-color);
  background-color: var(--background-color);
}
.inputs-register-company-form::-webkit-outer-spin-button,
.inputs-register-company-form::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Safari y Chrome */
  margin: 0; /* Elimina el espacio generado por las flechas */
}
.inputs-register-company-form::placeholder {
  color: #888;
  font-weight: 200;
  font-style: italic;
}

.register-company-required-message {
  color: red;
  font-size: 0.8rem;
}

.register-company-container-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.register-company-check-container {
  display: flex;
  justify-content: center;
  margin: 15px 0 0 0;
}

.check-icon {
  color: #1aa748;
  font-size: 100px;
}

.register-company-txt {
  display: flex;
  justify-content: center;
  margin: 10px 0 40px 0;
  font-size: 1.6rem;
  font-weight: 700;
}

.register-company-txt p {
  margin: 0;
}

.register-company-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

button.register-btn {
  width: 260px;
  text-transform: none;
}

.img-form-register-company {
  width: 50%;
  border-radius: 0 8px 8px 0;
  background-image: url("../../assets/images/ImgFormCompany.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

.register-company-txt {
  text-align: center;
}

.register-company-txt h4 {
  color: #444;
  font-size: 16px;
  margin-bottom: 12px;
}

.register-company-txt.rejected-color {
  color: #d52e22;
}

@media (max-width: 768px) {
  .img-form-register-company {
    display: none;
  }

  div.register-company-container {
    position: relative;
    width: min-content;
  }

  .register-company-form {
    width: auto;
  }

  p.title-register-company {
    font-size: 24px;
    margin: 8px;
  }

  .register-company-form-txt p {
    font-size: 16px;
  }

  .register-company-form-modal {
    margin: 0;
    width: 100%;
  }
}
