.img-form-consultant {
  width: 50%;
  border-radius: 8px 0 0 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

.consultant-contact-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  height: 600px;
  border-radius: 8px;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px 60px;
  width: 400px;
}

.modal-consultant-contact {
  display: flex;
  height: 600px;
}

.title-consultant-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  color: var(--text-color);
  margin: 12px;
}

.consultant-contact-txt {
  display: flex;
  text-align: center;
  margin: 0 0 20px 0;
  font-size: 1.1rem;
  color: var(--text-color);
}

.consultant-contact-txt p {
  margin: 0;
}

.consultant-contact-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.consultant-contact-field {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title-consultant-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 0.8rem;
  font-weight: 700;
  gap: 5px;
}

.inputs-consultant-contact {
  padding-left: 10px;
  border: 0;
  border-radius: 4px;
  outline: none;
  appearance: none;
  font-size: 1rem;
  color: var(--text-color);
  background-color: var(--background-color);
  padding: 16px;
  width: 380px;
}
.inputs-consultant-contact::-webkit-outer-spin-button,
.inputs-consultant-contact::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.inputs-consultant-contact::placeholder {
  color: #888;
  font-weight: 200;
  font-style: italic;
}

.selects-consultant-contact {
  height: 36px;
  padding-left: 5px;
  border: 0;
  border-radius: 4px;
  outline: none;
  font-size: 1rem;
  color: var(--text-color);
  background-color: var(--background-color);
}

.consultant-required-message {
  color: red;
  font-size: 0.8rem;
}

.consultant-container-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.consultant-continue-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 25%;
  gap: 20px;
}

.modal {
  height: 600px;
}

.card-container {
  width: 275px;
  border-radius: 8px !important;
  border: 1px solid #dee1e6ff;
  padding: 10px !important;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.card-container.focused {
  border: 1px solid var(--primary-hover-color);
}

.card-container.focused .review-btn {
  background: #efb03482;
  color: #5d4108ff;
}

.consultant-modal-img-review {
  border-radius: 8px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.review-btn {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 5px 0 0 5px;
  border-radius: 14px;
  border: none;
  background: #565e6c7d;
  color: white;
  font-size: 0.8rem;
  cursor: pointer;
}

.icon-star {
  font-size: 0.8rem;
  margin: 0 0 2px 2px;
}

.img-consultant {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.certified-container {
  font-size: 12px;
  padding: 0 10px 0 0;
  margin: 0;
  align-self: flex-end; /* Alinea el texto en la parte derecha del div */
  color: #bcc1ca;
  height: fit-content;
}

.certified-title {
  margin: 0;
  padding: 0;
}

.card-content {
  padding: 0 5px !important;
}

.experience-txt {
  font-size: 0.8rem;
  color: #9095a0;
}

.reviews-section {
  display: flex;
  justify-content: space-between;
}

.reviews-section h6 {
  color: #323842;
  margin: 8px 0;
}

.see-all-btn {
  color: var(--secondary-color) !important;
  font-size: 0.8rem !important;
  text-transform: none !important;
}

.arrow-see-all {
  font-size: 0.8rem !important;
}

.reviews-container {
  background-color: var(--background-color);
  border-radius: 4px;
  padding: 0 8px;
  height: 88px;
}

.user-review-section {
  display: flex;
  height: 36px;
  justify-content: space-between;
}

.user-review {
  height: 36px;
  display: flex;
  gap: 5px;
}

.user-review p {
  margin: 0;
}

.user-review img {
  height: 36px;
  width: 36px;
  border-radius: 18px;
  background: var(--background-color);
}

.name-user-review {
  font-size: 0.8rem;
  font-weight: 700;
}

.time-review {
  font-size: 0.8rem;
  font-weight: 400;
}

.review-txt p {
  font-size: 0.8rem;
  color: var(--text-color);
  margin: 8px 0 0 0;
}

.hire-btn {
  width: 270px !important;
}

.icon-hire-btn {
  font-size: 0.9rem !important;
}

.left-form-consultant {
  display: flex;
}

.modal-consultant-card {
  padding: 32px;
}

div.alert-success > div {
  background-color: #1fb24b;
  color: #fff;
}

.loading-page {
  display: flex;
  justify-content: center;
  margin: 32px;
}

@media (max-width: 768px) {
  .img-form-consultant {
    display: none;
  }

  div.consultant-contact-container {
    position: relative;
    width: auto;
    padding: 32px;
  }

  p.title-consultant-modal {
    font-size: 24px;
    margin: 8px;
  }

  .consultant-contact-txt p {
    font-size: 16px;
  }

  .consultant-contact-modal {
    margin: 0;
    width: auto;
  }

  .sap-modules-container {
    padding: 0;
    margin-right: -30px;
    margin-left: -8px;
  }
  .consultant-continue-form {
    height: 23%;
  }

  .inputs-consultant-contact {
    width: auto;
  }
  .title-consultant-inputs {
    width: auto;
  }
}
