.chat {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.chat-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.chat-inside-container {
  display: flex;
  padding: 0;
}

.section-btns {
  display: none;
}

.like-btn {
  color: var(--secondary-color);
}

.chat-text {
  width: 100%;
  padding: 32px 20%;
  color: var(--text-color);
}

.message {
  padding: 10px 24px;
  margin: 4px;
  border-radius: 8px;
  word-wrap: break-word;
  text-align: justify;
}

.sent-message {
  position: relative;
  max-width: 600px;
  margin-left: 32px;
  border-bottom-right-radius: 0;
  text-align: right;
  background: #ffefd4;
}

.sent-message:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  right: -14px;
  bottom: 0;
  border: 8px solid #ffefd4;
  border-color: transparent transparent #ffefd4 #ffefd4;
}

.received-message {
  position: relative;
  max-width: 600px;
  margin-right: 32px;
  border-top-left-radius: 0;
  text-align: justify;
  background-color: #f4f4f4; /* Color de fondo para mensajes recibidos */
}

.received-message:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: -14px;
  top: 0;
  border: 8px solid #f4f4f4;
  border-color: #f4f4f4 #f4f4f4 transparent transparent;
}

.outgoing-message {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.waiting-animation {
  display: flex;
  justify-content: left;
  padding-left: 25%;
}

.ask-expert {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin: 0 0 7px 0;
  padding-right: 21%;
  font-size: 0.9rem;
  color: var(--text-color);
}

.expert-btn {
  border-radius: 18px !important;
  width: 170px !important;
}

.input-chat-suggestions {
  padding: 0 21% 0 20%;
  margin-bottom: 100px;
}

.input-chat {
  display: flex;
  height: 48px;
  align-items: center;
  margin-bottom: 12px;
  padding: 0 10px 0 16px;
  border-radius: 24px;
  border: solid 1px #bcc1caff;
  gap: 8px;
  background-color: #fff;
}

.bar-chat {
  width: 100%;
  resize: none;
  overflow-y: scroll;
  border: none;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  color: #565e6c;
}

.bar-chat::placeholder {
  color: #888;
  font-weight: 200;
  font-style: italic;
}


.bar-chat:disabled {
  background-color: white;
}

.bar-chat::-webkit-scrollbar {
  display: none;
}

.stop-btn {
  color: var(--text-color) !important;
  background-color: var(--background-color) !important;
}

.stop-btn:hover{
  background-color: #e2e2e2 !important;
}

.suggestions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.9rem;
  gap: 12px;
  color: #9095a0ff;
}

.suggestions-btn {
  height: fit-content !important;
  font-size: 12px !important;
  text-transform: none !important;
  color: #9095a0ff !important;
  border-color: #bcc1caff !important;
  border-radius: 14px !important;
}

.LoadingPage {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .chat-text {
    width: 100%;
    padding: 0;
  }
  
  .waiting-animation {
    display: flex;
    justify-content: left;
    padding-left: 5%;
  }

  .ask-expert {
    padding-right: 5%;
  }

  .input-chat-suggestions {
    padding: 0 2%;
  }
}
