.footer-container {
    position: fixed;
    bottom: 8px;
    right: 8px;
    padding: 10px;
    border-radius: 32px;
    background: var(--background-color);
    border-bottom-right-radius: 0;
    display: none;
  }

.footer-container p {
  color: #565e6c;
}

.telegram-btn {
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 24px;
  gap: 8px;
}

.telegram-icon {
  width: 39px;
  height: 39px;
}

