.message-container {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 1;
  transform: translateY(0);
  margin: 16px 20%;
  color: #3a3a3a;
  background: #f0f8ff;
  width: fit-content;
  padding: 4px 16px;
  border-radius: 16px;
  font-size: 14px;
}

.hide-up {
  transform: translateY(-100%);
  opacity: 0;
}

.show {
  transform: translateY(0%);
  opacity: 1;
}

@media screen and (max-width: 600px) {
  .message-container {
    margin: 16px;
  }
}
