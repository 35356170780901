.sap-modules-container {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  position: relative;
}

.title-module {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 6px;
}

.modules-check {
  display: flex;
  align-items: center;
  transition: 0.4s;
  padding: 0;
  border-radius: 16px;
}

.modules-check:hover {
  background-color: #f6f6f6;
}

.modules-check label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--text-color);
}

.modules-check p {
  width: 90px;
  font-weight: 700;
  color: var(--secondary-color);
  margin: 0 5px 0 0;
}
span.MuiCheckbox-root {
  padding: 0;
  padding-right: 6px;
}

.sap-modules-chips {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  padding-right: 40px;
  margin-bottom: 8px;
}

.sap-modules-chip {
  background-color: #0085d2;
  border-radius: 16px;
  padding: 2px 6px;
  color: #fff;
  font-size: 13px;
}

.sap-modules-categories {
  left: 0;
  background: #fff;
  height: 220px;
  overflow: auto;
  box-shadow: 2px 5px 15px #0004;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 400px;
  margin-bottom: 32px;
}


@media (max-width: 768px) {
  .sap-modules-container {
    height: fit-content;

    overflow-y: auto;
  }

  .sap-modules-categories {
    width: 85%;
    height: auto;
  }
}
