.companies {
  .companies-container {
    padding: 16px;
  }

  button.btn-primary {
    width: fit-content;
    white-space: nowrap;
    background: white;
  }
}
