.search-cut-container {
  display: flex;
  padding: 8px 32px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

/* .search-cut {
  display: flex;
  align-items: center;
  gap: 8px;
} */

.search-cut {
  display: flex;
  justify-content: space-between;
  width: 500px;
  height: 25px;
  padding: 10px;
  border: 1px solid #bcc1caff;
  border-radius: 34px;
  border-width: 1px; 
}

.search-input {
  width: 100%;
  padding-left: 1%;
  border: none;
  outline: none;
  color: #565e6c;
  font-size: 0.9rem;
}

.search-input::placeholder {
  color: #888;
  font-weight: 200;
  font-style: italic;
}

/* #CogniSapLogoChat {
  width: 160px;
} */

.mic-btn {
  border: none;
  background-color: transparent;
}

.end-voice {
  color: red;
}

.question-btn{
  height: 44px !important;
  border-radius: 22px !important;
  text-transform: none  !important;
  color: var(--secondary-color) !important;
}

.modal-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  width: 70%;
  height: 70%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  padding: 20px;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.5);
  background-color: var(--background-color);
}

.header-modal {
  display: flex;
  justify-content: space-between;
}

h4.title-modal{
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--text-color);
}

.close-modal {
  cursor: pointer;
}

.close-btn{
  font-size: 1.5rem;
  color: var(--text-color);
}

.form-new-question {
  display: grid;
  height: 100vh;
  margin: 20px 0 0 0;
  grid-template-columns: 33% 23% 44%; /* Tres columnas de 100px cada una */
  grid-template-rows: 21% 21% 21% 21% 15%; /* Dos filas de 50px cada una */
}

.title-question {
  grid-column: 1 / span 2;
  grid-row: 1;
  padding: 0 20px 0 0;
}

.subtitles-form {
  margin: 0 0 5px 0;
  font-weight: bold;
  color: var(--text-color);
}

.title-question-input {
  display: flex;
  height: 36px;
  align-items: center;
  border: 1px solid #bcc1caff;
  border-radius: 4px;
  padding: 0 10px;
}

.title-input {
  width: 100%;
  outline: none;
  font-size: 0.9rem;
  border: none;
  color: var(--text-color);
  background-color: var(--background-color);
}

.title-input::placeholder{
  color: #888;
  font-weight: 200;
  font-style: italic;
}

.description-question {
  grid-column: 1 / span 2;
  grid-row: 2 / span 3;
  padding: 0 20px 0 0;
}

.description-input {
  width: 95%;
  height: 80%;
  padding: 10px;
  border: 1px solid #bcc1caff;
  border-radius: 4px;
  resize: none;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  color: var(--text-color);
  background-color: var(--background-color);
}

.description-input::placeholder{
  color: #888;
  font-weight: 200;
  font-style: italic;
}

.attachments {
  width: 91%;
  grid-column: 3;
  grid-row: 1 / span 2;
}

.attachments-files {
  height: 70%;
  border: 1px solid #bcc1caff;
  border-radius: 4px;
  padding: 15px 0 20px 20px;
  background-color: var(--background-color);
}

.attachments-files p {
  display: flex;
  flex-wrap: wrap;
  margin: 0 15px 15px 0;
  overflow: hidden;
  font-size: 0.9rem;
  color: var(--text-color);
}

.upload-files {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  border: 2px dashed #bcc1caff;
  background-color: var(--background-color);
  grid-column: 3;
  grid-row: 3 / span 2;
}

.upload-icon{
  font-size: 3rem;
  color: #bcc1caff;
}

.first-upload-txt{
  margin: 0;
  font-size: 0.9rem;
  color: var(--text-color);
}

.second-upload-txt{
  margin: 4;
  font-size: 0.8rem;
  color: #bcc1caff;
}


.upload-file-btn {
  padding: 4px;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  font-size: 0.8rem;
  color: var(--secondary-color);
  cursor: pointer;
}

.file-input{
  display: none;
}

.topics-action-btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  grid-column: 1 / span 3;
  grid-row: 5;
}

.detected-txt {
  display: flex;
  align-items: center;
  height: 28px;
  gap: 10px;
}

.detected-txt p {
  font-size: 0.8rem;
  color: var(--text-color);
}

.topic-btn {
  height: 28px !important;
  border-radius: 14px !important;
  font-size: 0.8rem !important;
  color: var(--secondary-color) !important; 
  border-color: var(--secondary-color) !important;
}

.action-btns {
  display: flex;
  gap: 10px;
}

.cancel-btn {
  height: 36px !important;
  border-radius: 4px !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  text-transform: none !important;
  color: #9095a0ff !important;
  background-color: #00000000 !important;
}

.cancel-btn:hover {
  color: #9095a0ff !important;
  background: #eaecefff !important;
}

.create-btn {
  height: 36px !important;
  border-radius: 4px !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  text-transform: none !important;
  color: white !important;
  background-color: var(--secondary-color) !important;
}

.create-btn:hover {
  color: #ffffffff !important;
  background: var(--secondary-hover-color) !important;
}



@media screen and (max-width: 845px) {
  /* #CogniSapLogoChat {
    width: 100%;
  } */

  /* .CognisapLogoCutSearch {
    width: 40%;
  } */

  .search-cut-container {
    padding: 8px;
  }

  .search-cut {
    width: 100%;
  }

  .modal-container {
    width: 95%;
    height: 96%;
  }

  .topic-btn {
    height: 20px !important;
    font-size: 8px !important;
    width: 20px !important;
    border-radius: 14px !important;
  }

  /* .CognisapLogoCutSearch {
    display: none;
  } */

  

  /* .SearchCut {
    width: 100%;
  } */
}
