.appbar {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1) !important;
}

.appbar-container {
  height: 55px;
}

.appbar-toolbar {
  min-height: 55px !important;
}

.menu-container {
  flex-grow: 1;
  display: flex;
  height: 55px;
}

.menu-appbar {
  display: block;
  color: #565e6c;
}

.navigation-bar {
  flex-grow: 1;
  display: flex;
  height: 55px;
}

button.menu-item-web {
  display: block;
  margin-right: 16px;
  padding-top: 10px;
  color: #565e6c;
  font-weight: 500;
  text-transform: none;
  transition: 0.6s;
  border-radius: 0;
  border-bottom: 4px solid var(--background-color);
}

button.menu-item-web:hover {
  color: var(--secondary-hover-color);
  font-weight: 500;
  border-bottom: 4px solid var(--secondary-hover-color);
}

button.menu-item-web.selected {
  color: var(--secondary-color);
  font-weight: 500;
  border-bottom: 4px solid var(--secondary-color);
}

p.menu-item-text {
  color: #565e6c;
  font-weight: 500;
}

.menu-item {
  font-weight: 500;
}

.menu-item:hover {
  color: var(--secondary-hover-color);
  border-right: 3px solid;
  border-radius: 0;
  background-color: var(--background-color) !important;
}

.menu-item:hover .menu-item-text {
  color:var(--secondary-hover-color);
  font-weight: 500;
}

.menu-item > .menu-item-text {
  font-weight: 500;
}

.cognisap-logo-link {
  cursor: pointer;
}

.cognisap-logo {
  display: flex;
  margin-right: 1rem;
  width: 160px;
}

.menu-dropdown-container{
  flex-grow: 0;
}

.menu-dropdown-inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.register-link {
  color: var(--secondary-color);
  font-weight: 500;
}

.register-link:hover {
  cursor: pointer;

}

.avatar-btn{
  padding: 0 !important;
}

.menu-dropdown-items {
  gap: 8px;
}

.menu-dropdown-items:hover{
  color: var(--secondary-hover-color);
  background-color: var(--background-color) !important;
  font-weight: bold;
}

.menu-dropdown-items:hover .user-name{
  color: var(--secondary-hover-color);
  background-color: var(--background-color) !important;
  font-weight: bold;
}

p.user-name{
  color: var(--text-color);
  font-weight: bold;
}

p.user-email{
  font-size: 0.8rem;
  color: #9095a0;
}

.menu-mobile {



}


/*-------- Responsive Design ------------*/

@media (max-width: 600px) {
  .menu-container {
    display: flex;
  }

  .cognisap-logo {
    display: flex;
  }

  .navigation-bar {
    display: none;
  }
  button.menu-item-web {
    width: 100%;
    text-align: left;
    padding: 6px 16px;
  }

}

@media (min-width: 768px) {
  .menu-container {
    display: none;
  }

  .cognisap-logo {
    display: flex;
  }
}
