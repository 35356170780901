.chat-history {
  padding: 16px;
  height: calc(100% - 32px);
  border-radius: 26px;
  background-color: #fff;
}

.chat-history-list {
  list-style: none;
  padding: 0;
  height: calc(100% - 112px);
  overflow: auto;
  border-radius: 16px;
  margin-right: -16px;
  padding-right: 16px;
}

.chat-history-item {
  background-color: aliceblue;
  margin-bottom: 8px;
  border-radius: 8px;
  padding: 8px;
  font-size: 14px;
}

.chat-history-item:hover {
  background-color: rgb(223, 238, 252);
  cursor: pointer;
}

.chat-history-item-active {
  background-color: rgb(197, 223, 247);
}

.chat-history-item-text {
  font-weight: 500;
  margin-bottom: 4pxss;
}

.chat-history-item-time {
  text-align: right;
  color: #888;
  margin-top: 4px;
  font-size: 12px;
}

button.btn-chat {
  border-radius: 16px;
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background: aliceblue;
}
button.btn-chat:hover {
  background: rgb(199, 215, 230);
}

.btn-chat-icon {
  padding: 8px;
  background-color: #006eba;
  border-radius: 16px;
  color: #fff;
}

button.btn-primary.btn-open-history {
  display: none;
}

@media screen and (max-width: 600px) {
  .chat-history {
    height: 34px;
    overflow: hidden;
    background-color: rgb(148, 190, 226);
    padding: 4px;
    border-radius: 0;
    transition: 0.4s;
  }

  .chat-history.chat-open {
    position: absolute;
    top: 55px;
    height: 100%;
    z-index: 3;
  }

  button.btn-primary {
    border-radius: 24px;
    padding: 0 8px;
    height: 34px;
  }

  .btn-chat-icon {
    padding: 4px;
  }

  .chat-history-btns {
    display: flex;
    justify-content: space-between;
  }

  button.btn-primary.btn-open-history {
    width: 40px;
    display: flex;
    justify-content: center;
  }

  .received-message {
    text-align: left;
  }
}
