.forum {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.forum-container {
  display: flex;
  justify-content: center;
  padding: 24px;
  gap: 30px;
  overflow: hidden;
}

.questions-forum {
  width: 60%;
  height: 100%;
}

.questions-btns {
  display: none;
  justify-content: right;
  color: #9095a0;
  gap: 10px;
}

.questions-btns p {
  cursor: pointer;
  font-size: 0.8rem;
  text-decoration: underline;
  padding: 8px;
}

.questions {
  height: calc(100% - 40px);
  overflow: auto;
}

.user-questions-forum {
  display: none;
  height: 77px;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ccc;
  margin-right: 12px;
  margin-bottom: 12px;
  border-radius: 4px;
}

.user-questions-forum img {
  height: 36px;
  width: 36px;
  border-radius: 18px;
  background: var(--background-color);
}

.image-user-question {
  display: flex;
}

.user-question-data {
  display: flex;
}

.name-user-question {
  font-size: 0.8rem;
  font-weight: 700;
  margin: 0 0 0 5px;
  color: var(--text-color);
}

.time-question {
  font-size: 0.8rem;
  font-weight: 400;
  color: #9095a0ff;
  margin: 0 0 0 10px;
}

.question-txt {
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--text-color);
  margin: 0 0 0 5px;
}

.question-valoration {
  display: flex;
  gap: 15px;
}

.like-question {
  display: flex;
  align-items: center;
  color: #9095a0ff;
  gap: 5px;
}

.like-question p {
  margin: 0;
  font-size: 0.8rem;
}

.comment-question {
  display: flex;
  align-items: center;
  color: #9095a0ff;
  gap: 5px;
}

.comment-question p {
  margin: 0;
  font-size: 12px;
}

.question-valoration-icon{
  color: #9095a0;
}

.topics-forum {
  background: var(--background-color);
  width: 230px;
  height: 370px;
  padding: 24px;
  border-radius: 8px;
}

.topics-forum p {
  font-size: 14px;
  color: var(--text-color);
  margin: 2px;
}

.topics-forum h5 {
  color: #565e6c;
  font-weight: 500;
  margin-top: 12px;
  margin-bottom: 4px;
}

hr.divider-topics{
  margin-top: 22px;
}

.no-found-questions {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.search-Off-icon {
  width: 118px;
  height: 118px;
}

.no-found-txt{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-found-txt p {
  margin: 0;
  font-size: 20px;
  color: var(--secondary-color);
}

.back-btn-container {
  background-color: var(--background-color);
}

@media screen and (max-width: 600px) {

  .forum-container {
    padding: 0;
  }

  .questions-forum {
    width: fit-content;
  }

 

  .question-txt {
    margin: 0 0 0 5px;
    height: 40px;
    overflow: hidden;
  }

  .search-Off-icon {
    width: 70px;
    height: 70px;
  }

  .topics-forum {
    display: none;
  }
  
}
